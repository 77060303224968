@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'CopperplateBold';
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url('../public/fonts/CopperplateBold.ttf') format('ttf');
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: theme('colors.eerie-black');
  font-size:24px;
}

p {
  padding-bottom: 24px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  color: theme('colors.white');
  font-size: 42px;
  font-family: CopperplateBold;
  font-weight: bold;
}

h2 {
  color: theme('colors.white');
  font-size: 24px;
  font-family: CopperplateBold;
  font-weight: bold;
}

.App {
  text-align: center;
}



.giving-back-ring .small {
  animation: linear 35s rotate reverse infinite;
}

.giving-back-ring .medium {
  animation: linear 40s rotate infinite;
}

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
    transform-origin: center;
  }
  to {
    transform: rotateZ(360deg);
    transform-origin: center;
  }
}

*:focus {
  outline: none;
}

input, textarea {
  color: white;
  caret-color: white;
}
